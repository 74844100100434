<template>
    <div>
        <div class="vx-row">Settings Overview</div>
    </div>
</template>
]

<script>
import { http } from '@/services'

export default {
    name: 'SettingsView',
    props: [],
    components: {},

    watch: {},

    data() {
        return {}
    },
    methods: {},
}
</script>
